<template>
  <div class="page search">
    <!-- <SearchBar
      v-if="browserType === 'landscape'"
      :isSearch="true"
      :isShowRight="false"
      :isShowSearchBtn="true"
      placeholder=""
      title="搜索"
      ref="searchRef"
      @search="handleOnSearch"
    /> -->
    <!-- <template> -->
    <HeadBar
      class="search-headbar"
      title="搜索"
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="true"
    />
    <PureSearch
      class="search-bar-pure"
      :isSearch="true"
      placeholder=""
      ref="searchRef"
      @search="handleOnSearch"
    />
    <!-- </template> -->

    <div class="search-content">
      <div
        class="song-list song-list-padding"
        v-if="keyword"
        :class="shouldShowBanner && 'has-banner'"
      >
        <div class="tabs">
          <div
            class="tab"
            :class="{ active: curSearchTab === 'song' }"
            @click="handleSwitchSearchTab('song')"
          >
            歌曲
          </div>
          <div
            class="tab"
            :class="{ active: curSearchTab == 'singer' }"
            @click="handleSwitchSearchTab('singer')"
          >
            歌手
          </div>
        </div>
        <div v-if="curSearchTab === 'song'">
          <LoadMore
            class="song-list-content"
            v-if="resultData.song.length"
            @load-more="getSearchResult"
            safeAreaHeight="12.6991vw"
          >
            <div
              v-if="shouldShowBanner"
              class="search-banner"
              @click="handleClickBanner"
              v-log="{
                event_type: 'show',
                event_name: 30192,
                event_data: {
                  str1: '搜索结果页',
                  str2: '歌曲结果',
                  str3: '列表运营位展示',
                  str4: 'show',
                },
              }"
            ></div>
            <SongItem
              v-for="(songItem, index) in resultData.song"
              :key="index"
              :songItem="songItem"
              :log-from="{
                song_list_source: 2,
                fr2: '搜索页会员歌曲',
              }"
            ></SongItem>
          </LoadMore>
          <div v-else class="empty-wrapper">
            <div class="empty-block">
              <img
                src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png"
                alt=""
              />
              <p>抱歉，暂无“{{ keyword }}”结果</p>
            </div>
            <GuessSonglist :pageSize="6" renderType="block" />
          </div>
          <p class="hint" v-if="isEmpty && resultData.song.length > 0">
            已加载全部
          </p>
        </div>
        <div v-else>
          <LoadMore
            class="singer-list"
            ref="loadMoreRef"
            v-if="resultData.singer.length"
            @load-more="getSearchResult"
            safeAreaHeight="12.6991vw"
          >
            <SingerItem
              v-for="(item, index) in resultData.singer"
              :key="index"
              :singer="item"
              @click="handleClickSinger(item)"
            ></SingerItem>
          </LoadMore>
          <div v-else class="empty-wrapper">
            <div class="empty-block">
              <img
                src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png"
                alt=""
              />
              <p>抱歉，暂无“{{ keyword }}”结果</p>
            </div>
            <GuessSonglist :pageSize="6" renderType="block" />
          </div>
          <p class="hint" v-if="isEmpty && resultData.singer.length > 20">
            已加载全部
          </p>
        </div>
      </div>
      <SearchHistory v-if="!keyword" @clickWord="handleChangeInput" />
      <!-- <GuessSong v-if="!keyword && isLogin" /> -->
      <GuessSonglist
        v-if="!keyword"
        :pageSize="browserType === 'landscape' ? 4 : 6"
        renderType="block"
      />
    </div>

    <div class="search-bottom">
      <img
        src="https://qncweb.ktvsky.com/20240910/vadd/f35d83caa9333332d728fd34a4f96bd4.png"
        alt=""
      />
    </div>

    <!-- 功能下线 -->
    <!-- <RecommendSong :isActive="isShowRecommend" from="search" @close="handleCloseRecommend" /> -->
  </div>
</template>

<script>
import SearchHistory from '@/components/search-history/index.vue'
import SingerItem from '@/components/singer-item/index.vue'
import SongItem from '@/components/song-item/index.vue'
import { computed, onActivated, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
// import GuessSong from '@/components/guess-song/index.vue'
import GuessSonglist from '@/components/guess-song/songlist.vue'
import { sendLog } from '@/directives/v-log/log'
import { search } from '@/service/search'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
// import RecommendSong from "@/pages/singer/components/recommend-song/index.vue";

import { useShareBrowserSize } from '@/composables/sharedComposable'
import useRecommend from '@/composables/useRecommend'
import useVip from '@/composables/useVip'
import { setSearchCache } from '@/utils/historyCache'
import get from 'lodash/get'

import HeadBar from '@/components/head-bar/index.vue'
import { vipLogFrom } from '@/constants/index'
import PureSearch from './components/search-bar/index.vue'

import logParams from '@/log'
import {
  getCloseSearchRecommendTag,
  getReduceSearchRecommendSetting,
  setCloseSearchRecommendTag,
} from '@/utils/historyCache'

export default {
  name: 'Search',
  components: {
    SongItem,
    SingerItem,
    // GuessSong,
    GuessSonglist,
    SearchHistory,
    HeadBar,
    PureSearch,
    // RecommendSong,
  },
  activated() {
    const store = useStore()
    const { isUsed, position } = store.state.pageCacheData.search
    if (!isUsed) {
      this.handleInitData()
      this.handleClearInput()
    } else {
      if (this.$refs.loadMoreRef) {
        this.$refs.loadMoreRef.root.scrollTop = position
      }
      this.handleUpdateCachePosition(false, 0)
    }
    this.handleFocusInput() //只要进入就锁定

    if (this.curSearchTab === 'singer') {
      sendLog({
        event_type: '10000~50000',
        event_name: 30162,
        event_data: {
          str1: '歌手',
          str2: '搜索',
          str3: '搜索框点击',
          str4: 'click',
        },
      })
    } else {
      sendLog({
        event_type: '10000~50000',
        event_name: 10019,
        event_data: {
          str1: '搜索页',
          str2: '搜索页',
          str3: '进入搜索页',
          str4: 'show',
        },
      })
    }
  },
  methods: {
    handleClearInput() {
      if (this.$refs.searchRef) this.$refs.searchRef.handleClearInput()
    },
    handleFocusInput() {
      if (this.$refs.searchRef) this.$refs.searchRef.handleFocusInput()
    },
    handleChangeInput(e) {
      console.log('search word')
      sendLog({
        event_type: '10000~50000',
        event_name: 10020,
        event_data: {
          str1: '搜索页',
          str2: '搜索历史',
          str3: '点击歌曲',
          str4: 'click',
        },
      })
      this.handleChangeKeyword(e)
      this.$refs.searchRef.handleChangeKeyword(e)
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { browserType } = useShareBrowserSize()
    const { showRecommendConfirm } = useRecommend()
    const isShowRecommend = ref(false)

    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const searchCacheList = computed(() => store.state.search.searchCache)
    const shouldShowBanner = computed(() => {
      const isSongTab = curSearchTab.value === 'song'
      const vipSongsCount = resultData.value.song.filter(
        (item) => item.is_vip
      ).length

      return isSongTab && vipSongsCount > 3
    })
    const orderedListNumber = computed(() => {
      return store.state.orderedList.length
    })

    let curSearchTab = ref('song')
    let loadMoreRef = ref(null)
    let keyword = ref('')
    let isEmpty = ref(false)
    let resultData = ref({
      singer: [],
      song: [],
    })
    let paginationNumberRecord = {
      singer: 1,
      song: 1,
    }
    let isRequest = false
    // let isShowVipActEnter = ref(false)
    const searchRef = ref(null)

    const handleInitData = () => {
      const params = route.query?.defaultTab
      if (params) {
        curSearchTab.value = params
      }
      console.log(
        'handleInitData',
        'params',
        params,
        'curSearchTab.value',
        curSearchTab.value
      )

      keyword.value = ''
      resultData.value.singer = []
      resultData.value.song = []
    }

    const handleSwitchSearchTab = (tab) => {
      curSearchTab.value = tab
      // TODO
      if (!resultData.value[tab].length) {
        searchHandler[tab].call()
      }
    }
    const handleOnSearch = (k) => {
      if (!k.trim()) {
        showVipQrcode({
          log: '搜索页-搜索栏-空词搜索弹窗',
          isLogin: isLogin.value,
          fr: vipLogFrom.get('搜索页-搜索栏-空词搜索弹窗'),
        })
        sendLog({
          event_type: 'show',
          event_name: 30191,
          event_data: {
            str1: '搜索页',
            str2: '搜索栏',
            str3: '空词搜索弹窗',
            str4: 'show',
            str5: isLogin.value ? '已登录' : '未登录',
          },
        })
        return
      }
      keyword.value = k
    }

    const handleClickSinger = ({ singer, singerhead, singerid }) => {
      // console.log('handleClickSinger:', singer, singerhead, singerid)
      router.push({
        name: 'songList',
        query: {
          name: singer,
          image: singerhead,
          singerid,
        },
      })
    }

    const getSearchReportStatus = (res) => {
      if (res.errcode !== 200) {
        return 12
      }
      return res.singer.length || res.song.length ? 10 : 11
    }

    // 优先展示搜索的vip歌曲
    const toSongvipSort = (arr) => {
      let arr1 = [],
        arr2 = []
      for (let i in arr) {
        if (arr[i].is_vip) {
          arr1.push(arr[i])
        } else {
          arr2.push(arr[i])
        }
      }
      return arr1.concat(arr2)
    }

    const searchHandler = {
      singer: async () => {
        const bussinessResponseData = await search(
          keyword.value,
          paginationNumberRecord.singer,
          'singer'
        )
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(bussinessResponseData),
          },
        })
        if (bussinessResponseData.singer.length) {
          resultData.value.singer = resultData.value.singer.concat(
            bussinessResponseData.singer
          )
          paginationNumberRecord.singer++
        }
        isRequest = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10021,
          event_data: {
            str1: '搜索页',
            str2: '搜索栏',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
      song: async () => {
        const bussinessResponseData = await search(
          keyword.value,
          paginationNumberRecord.song,
          'song'
        )
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(bussinessResponseData),
          },
        })
        if (bussinessResponseData.song.length) {
          const songvipsort = bussinessResponseData.song // toSongvipSort(bussinessResponseData.song) - 转为服务端处理
          setSearchSongList(songvipsort)
          resultData.value.song = resultData.value.song.concat(songvipsort)
          paginationNumberRecord.song++
        }
        isRequest = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10021,
          event_data: {
            str1: '搜索页',
            str2: '搜索栏',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
    }

    const getSearchResult = async () => {
      if (isRequest) {
        return
      }
      isRequest = true
      searchHandler[curSearchTab.value].call()
    }

    const handleChangeKeyword = (e) => {
      if (e) keyword.value = e
    }

    const setSearchCacheList = (k) => {
      // 存储搜索历史
      if (!searchCacheList.value.includes(k)) {
        let newSearchCacheList = [k, ...searchCacheList.value]
        newSearchCacheList = newSearchCacheList.slice(0, 10)
        store.dispatch('search/updateSearchCache', newSearchCacheList)
        setSearchCache(newSearchCacheList)
      }
    }

    const setSearchSongList = (list) => {
      // 搜索词条时 如果第一页中有一首歌名与搜索内容一致 则符合一键演唱功能
      if (!!keyword.value && !resultData.value.song.length) {
        list.some((v) => {
          const music_name = get(v, 'music_name', '').split('(HD)')[0]
          if (music_name === keyword.value) {
            store.dispatch('search/addSearchSong', {
              ...v,
              searchname: keyword.value,
            })
            return true
          }
          return false
        })
      }
    }

    const handleCloseRecommend = (isHasControl) => {
      console.log(isHasControl, 'handleCloseRecommend isHasControl')
      isShowRecommend.value = false
      if (!isHasControl) {
        // open recommend song modal logic
        const closeTag = getCloseSearchRecommendTag()
        if (closeTag > 2) return
        if (closeTag === 2) {
          //3次无操作
          showRecommendConfirm()
          const closeLogParams = logParams.get(30304)
          closeLogParams.event_data.str1 = '搜索页'
          sendLog(closeLogParams)
        }
        setCloseSearchRecommendTag(closeTag + 1)
      }
    }

    const handleUpdateCachePosition = (u, v) => {
      store.commit('UPDATE_PAGE_CACHEDATA', {
        data: {
          isUsed: u,
          position: v,
        },
        type: 'search',
      })
    }

    const handleClickBanner = () => {
      showVipQrcode({
        log: '搜索结果页-歌曲结果-列表运营位弹窗',
        isLogin: isLogin.value,
        fr: vipLogFrom.get('搜索结果页-歌曲结果-列表运营位弹窗'),
      })
      sendLog({
        event_type: 'show',
        event_name: 30193,
        event_data: {
          str1: '搜索结果页',
          str2: '歌曲结果',
          str3: '列表运营位弹窗',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
        },
      })
    }

    watch(keyword, (k) => {
      if (k) {
        resultData.value = {
          singer: [],
          song: [],
        }
        paginationNumberRecord = {
          singer: 1,
          song: 1,
        }
        getSearchResult()
        setSearchCacheList(k)
      }
    })

    /**
     * 1 - 保持推荐 3
     * 2 - 减少推荐频率 0
     * 3 - 从不推荐
     */
    const startRecommend = () => {
      const setting = getReduceSearchRecommendSetting()
      if (setting === 3) return
      const show =
        setting === 1
          ? orderedListNumber.value < 4
          : orderedListNumber.value === 0
      if (show) {
        isShowRecommend.value = true
        sendLog(logParams.get(30300))
      }
    }

    const closeRecommend = () => {
      isShowRecommend.value = false
    }

    onMounted(() => {
      // if (checkLandscapeOrPortrait() === 'landscape') {
      //   isShowVipActEnter.value = true
      // }
      // startRecommend();
    })

    onActivated(() => {
      console.log('onActivated')
      startRecommend()
    })

    onBeforeRouteLeave((to, from, next) => {
      closeRecommend()
      if (to.name === 'songList') {
        const position = loadMoreRef.value
          ? loadMoreRef.value.root.scrollTop
          : 0
        handleUpdateCachePosition(true, position)
      } else {
        searchRef.value.resetValue()
      }
      next()
    })

    return {
      isLogin,
      isEmpty,
      curSearchTab,
      resultData,
      keyword,
      loadMoreRef,
      // isShowRecommend,
      // isShowVipActEnter,
      handleSwitchSearchTab,
      handleOnSearch,
      handleClickSinger,
      handleChangeKeyword,
      setSearchCacheList,
      getSearchResult,
      handleInitData,
      handleUpdateCachePosition,
      shouldShowBanner,
      handleClickBanner,
      searchRef,
      browserType,
      // handleCloseRecommend,
    }
  },
}
</script>

<style lang="stylus" scoped>
.search
  height 100vh
  overflow hidden
  display flex
  flex-direction column
  background: #000000B2
  // padding-bottom 110px
  padding-bottom 0px
  padding-top 0px
  .search-bar
    background none
  .song-list-padding
    padding 0 !important
    height auto
  .song-list
    flex 1
    overflow hidden
    width 100% !important
    box-sizing border-box
    display flex
    flex-direction column
    ::-webkit-scrollbar
      display none
    &.has-banner
      .song-list-content
        margin-top 0px!important
    &-content
      margin-top 76px
      position relative
      padding-left 100px
      padding-right 100px
      padding-bottom 300px!important
      ::v-deep .song
        width 100%
        height 141px
        .song-item
          padding 0 20px
          .right
            margin-right 0
      @media screen and (max-width 1200px)
        margin-top 40px
        padding-left 0
        padding-right 0
        padding-bottom 950px!important
    .tabs
      width calc(100% - 200px)
      height 100px
      margin-left 100px
      border-bottom 2px solid rgba(255, 255, 255, 0.10)
      display flex
      justify-content center
      align-items center
      .tab
        display flex
        align-items center
        justify-content center
        width 96px
        height 100px
        color rgba(255, 255, 255, 0.4)
        font-size 32px
        &:nth-child(1)
          margin-right 394px
      .active
        border-bottom 2px solid #DBAE6A
        color rgba(219, 174, 106, 1)
    .search-banner
      background url('https://qncweb.ktvsky.com/20240314/other/6f50d9c6a4389266b3e50c2129baa53d.png') no-repeat
      // width 1560px
      // height 100px
      width 760px
      height auto
      background-size 100% auto
      // margin 20px auto 20px
      margin-top 20px
      position relative
    .empty
      overflow-y scroll
    & > div:not(.tabs):not(.search-banner)
      flex 1
      overflow hidden
  .singer-list
    flex 1
    margin 0 auto
    text-align center
    display flex
    flex-wrap wrap
    box-sizing border-box
    display grid
    // grid-template-columns repeat(6, 216px)
    grid-template-columns repeat(4, 174px)
    justify-content space-between
    padding 50px 80px 150px 80px !important
    .singer-item
      width 174px
      height 218px
      margin-bottom 50px
      ::v-deep
        .singer-item-cover
          width 174px
          height 174px
          margin-bottom 17px
        p
          width 174px
          line-height 1
    @media screen and (max-width 1200px)
      padding 40px 0 850px 0 !important
      grid-template-columns repeat(4, 200px)
      .singer-item
        width 200px
        height 246px
        margin-bottom 60px
        ::v-deep
          .singer-item-cover
            width 200px
            height 200px
            margin-bottom 20px
          p
            width 200px
            line-height 1
  .empty-wrapper
    overflow-y scroll
    height 100%
    padding-bottom 200px
  .empty-block
    // margin-top 240px
    margin-top 100px
    font-size 28px
    color rgba(255, 255, 255, 0.4)
    text-align center
    display flex
    flex-direction column
    align-items center
    justify-content center
    img
      margin-bottom 40px
      width 90px
      height 90px
    @media screen and (max-width 1200px)
      margin 130px 0 130px
  .hint
    text-align center
    color #555555
  ::v-deep .search-history
    padding 0 80px
    margin-top 20px
    max-height 237px
    overflow-y scroll
    @media screen and (max-width 1200px)
      padding 0
  ::v-deep .sec-gusse-sing
    padding 0 80px
    // margin-top 80px
    margin-top 30px
    @media screen and (max-width 1200px)
      padding 0
    .section-container-header-title
      font-size 28px
      color rgba(255, 255, 255, 0.4)
      @media screen and (max-width 1200px)
        font-size 24px
    .sec-gusse-sing-change span
      color rgba(255, 255, 255, 0.4)
    .sec-gusse-sing-list
      grid-template-columns repeat(2, 393px)
      @media screen and (max-width 1200px)
        grid-template-columns repeat(3, 344px)
    .song-block, .song
      width 393px
      min-height 160px
      margin-bottom 0px
      @media screen and (max-width 1200px)
        width 344px
    .song
      margin-bottom 40px
      @media screen and (max-width 1200px)
        margin-bottom 24px
        height 140px !important
        min-height 140px
    .song-block
      @media screen and (max-width 1200px)
        height 140px !important
        min-height 140px
  .search-content
    display flex
    flex-direction column
    align-items center
    // justify-content center
    width 986px
    height 916px
    position fixed
    right 0
    // top 164px
    // top 145px
    top 292px
    @media screen and (max-width 1200px)
      width 1080px
      height 992px
      position absolute
      top 887px
      left 60px
  .search-bottom
    position absolute
    bottom 50px
    left 80px
    width 854px
    height 346px
    // background url('https://qncweb.ktvsky.com/20240910/vadd/f35d83caa9333332d728fd34a4f96bd4.png') no-repeat
    // background-size 100% auto
    img
      width 100%
      height 100%
    @media screen and (max-width 1200px)
      display none
  .search-headbar
    background transparent
  .search-bar-pure
    background transparent
    position fixed
    top 164px
    left 1014px
    width 826px
    padding 0
    // margin auto
    @media screen and (max-width 1200px)
      top 775px
      left 0
      right 0
      bottom 0
      width 100%
      padding 0 5vw
</style>
